@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #333;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  background: #f6f4e4;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

.inner2 {
  max-width: 960px;
  margin: 0 auto;
}

.inner3 {
  max-width: 1560px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  padding-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents_wrap {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    margin-bottom: 50px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

.imgR, .imgL {
  max-width: 100% !important;
  float: none;
  margin-left: 0 !important;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  margin: 0;
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  padding: 0 10px !important;
}

.nav_btnwrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
}

@media only screen and (max-width: 834px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
}

.hed_right .btnwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .hed_right .btnwrap {
    justify-content: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .hed_right .btnwrap {
    justify-content: center;
  }
}

.hed_right .btnwrap .tel2 {
  width: 300px;
  font-size: 1.5em;
  background: #009445;
  padding: 15px;
  color: #fff;
  font-weight: bold;
}

.hed_right .btnwrap .tel2 span {
  font-size: 0.7em;
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  .hed_right .btnwrap .tel2 {
    width: 100%;
    font-size: 1.2em;
  }
}

.hed_right .btnwrap .tel2 a {
  color: #fff;
  display: block;
}

.hed_right .btnwrap .tel2 div {
  font-weight: bold;
}

.hed_right .btnwrap .cont {
  width: 250px;
}

@media only screen and (max-width: 834px) {
  .hed_right .btnwrap .cont {
    width: 100%;
  }
}

.hed_right .btnwrap .cont a {
  padding: 15px;
  display: block;
  background: #009445;
  font-size: 1.25em;
  color: #fff;
  font-weight: bold;
}

.hed_right .btnwrap .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  .hed_right .btnwrap .spnone {
    display: none;
  }
}

#hedInfo {
  max-width: 555px;
}

#hedInfo .ttl {
  padding: 2px 5px;
  background-color: #0066FF;
  line-height: 1;
}

@media only screen and (max-width: 834px) {
  #hedInfo .ttl {
    padding: 10px;
  }
}

#hedInfo ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  #hedInfo ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hedInfo ul {
    justify-content: center;
  }
}

#hedInfo ul li:nth-of-type(2) {
  margin-right: 10px;
}

@media only screen and (max-width: 834px) {
  #hedInfo {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    max-width: 100%;
    background-color: #f3f3f3;
  }
}

.hed_left {
  width: 15%;
}

@media screen and (min-width: 834px) and (max-width: 1348px) {
  .hed_left {
    text-align: center;
    width: 25%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1050px) {
  .hed_left {
    width: 18%;
  }
}

@media only screen and (max-width: 834px) {
  .hed_left {
    width: 100%;
    text-align: center;
  }
}

#header {
  width: 100%;
  z-index: 2;
  transition: 0.5s ease-in-out;
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s ease-in-out;
  padding: 20px;
}

@media only screen and (max-width: 640px) {
  #header .hedwrap {
    display: block;
    padding: 10px 0 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .hedwrap {
    align-items: center;
    padding: 10px 0;
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #header .hedwrap {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 640px) {
  #header #siteID {
    padding: 10px 0 !important;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header #siteID {
    padding: 10px 0 !important;
    text-align: center;
  }
}

#header #siteID a {
  opacity: 1;
}

#header .telwrap {
  margin-right: 20px;
}

#header .time {
  font-size: 14px;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .time {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #header .time {
    display: none;
  }
}

.hed_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_info {
    display: block;
  }
}

.hed_info .hed_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .hed_info .hed_cont {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_info .hed_cont {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .navwrap {
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1050px) {
  .navwrap {
    margin-right: 0;
  }
}

.contwrap .time {
  text-align: left;
  font-size: 0.8em;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .contwrap {
    width: 100%;
  }
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.spnone {
  display: block;
}

@media only screen and (max-width: 834px) {
  .spnone {
    display: none;
  }
}

#nav_global .tel2 a {
  background: #009445;
  padding: 15px;
  display: block;
  color: #fff;
  font-size: 1.6em;
}

#nav_global .inner {
  padding: 0;
}

@media only screen and (max-width: 834px) {
  #nav_global .inner {
    padding: 100px 30px;
  }
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
  }
}

#nav_global ul li {
  position: relative;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li {
    text-align: center;
    border-bottom: 1px solid #009445;
  }
}

#nav_global ul li a {
  color: #333;
  display: block;
  padding: 10px 20px;
  position: relative;
  display: block;
  text-decoration: none;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #nav_global ul li a {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global ul li a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1050px) {
  #nav_global ul li a {
    padding: 10px;
  }
}

#nav_global ul li a span {
  display: block;
  font-size: 0.7em;
  font-weight: 400;
}

#nav_global ul li a:hover {
  opacity: 0.6;
}

#nav_global ul li a:hover::after {
  transform: scale(1, 1);
}

#nav_global ul ul {
  position: absolute;
  width: 215px;
  z-index: 100;
  top: 100%;
  left: -44%;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#nav_global ul ul li a {
  color: #333;
}

.mainArea {
  width: 100%;
}

.mainArea img {
  height: 690px !important;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 830px;
  min-width: 830px;
  height: 100%;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    width: 150px !important;
    min-width: 150px !important;
    max-height: 336px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    width: 128px !important;
    min-width: 128px !important;
    max-height: 300px;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: bottom;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 180px 0;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    padding: 130px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.drawer-hamburger {
  z-index: 101 !important;
}

.drawer-hamburger span {
  background: #333 !important;
}

.drawer-hamburger span:before, .drawer-hamburger span:after {
  background: #333 !important;
}

.drawer-hamburger.change-color span {
  background: #2e4121 !important;
}

.drawer-hamburger.change-color span:before, .drawer-hamburger.change-color span:after {
  background: #2e4121 !important;
}

.drawer-hamburger:after {
  content: "menu";
  position: absolute;
  bottom: 2px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.drawer-nav {
  z-index: 101 !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 20px;
}

.drawer-hamburger {
  z-index: 201 !important;
}

.drawer-overlay {
  z-index: 201 !important;
}

.drawer-nav {
  z-index: 202 !important;
  background-color: #fff !important;
}

.drawer-open .drawer-hamburger .drawer-hamburger-icon {
  background-color: transparent !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #333 !important;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .for-pc {
    display: none !important;
  }
}

.not-for-pc {
  display: none !important;
}

@media only screen and (max-width: 834px) {
  .not-for-pc {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .not-for-sp {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #333;
  text-align: center;
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  padding: 10px;
  margin: 50px 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer li a {
  color: #333;
  text-decoration: none;
  padding: 16px;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.address {
  margin-top: 20px;
}

.pagetop {
  position: fixed;
  bottom: 10%;
  right: 10px;
  z-index: 2;
}

.ftlogo {
  padding-top: 48px;
  font-size: 24px;
}

.small_bg {
  background: #b20000;
  padding: 5px 0;
  color: #fff;
}

.small_bg a {
  color: #fff;
}

#footer {
  padding: 0;
}

@media only screen and (max-width: 834px) {
  #footer .tel2 {
    margin-bottom: 50px;
  }
}

#footer .tel2 a {
  color: #333;
}

.footer2 {
  background: url(../images/common/cont_bg.jpg);
  background-size: cover;
  padding: 60px 0;
}

@media only screen and (max-width: 640px) {
  .footer2 {
    background-position: center;
    padding: 70px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 {
    padding: 100px 0;
    background-position: center;
  }
}

.footer2 .ft_h2 {
  position: relative;
  margin-bottom: 10px;
}

.footer2 .ft_h2 h2 {
  font-size: 1.8em;
  color: #fff;
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  line-height: 1em;
}

.footer2 .ft_h2 h2:before {
  content: '';
  position: absolute;
  top: 65%;
  display: inline-block;
  width: 220px;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #cccccc;
}

.footer2 .ft_h2 span {
  font-size: 0.5em;
  display: block;
  padding-top: 15px;
  font-weight: 500;
  color: #b20000;
}

.footer2 .ft_txt {
  color: #fff;
  margin: 40px 0 50px;
}

.footer2 .ftwrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap {
    display: block;
    padding: 15px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 .ftwrap {
    display: block;
    padding: 15px;
  }
}

.footer2 .tel2 {
  width: 250px;
  font-size: 1.4em;
  border: 1px solid #fff;
  padding: 20px;
  color: #fff;
  display: block;
  white-space: nowrap;
}

.footer2 .tel2 a {
  color: #fff;
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .footer2 .tel2 {
    width: 260px;
    padding: 20px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 834px) {
  .footer2 .tel2 {
    width: 100% !important;
    margin-top: 30px;
  }
}

.footer2 .form a {
  width: 250px;
  font-size: 1.4em;
  border: 1px solid #fff;
  padding: 20px;
  color: #fff;
  display: block;
  white-space: nowrap;
  margin-right: 30px;
}

@media only screen and (max-width: 834px) {
  .footer2 .form a {
    width: 100% !important;
    margin-top: 30px;
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .footer2 .form a {
    width: 260px;
    padding: 20px;
  }
}

.footer2 .baikyaku a {
  width: 300px;
  font-size: 1.4em;
  border: 1px solid #fff;
  padding: 20px;
  color: #fff;
  display: block;
  white-space: nowrap;
  margin-right: 30px;
}

@media only screen and (max-width: 834px) {
  .footer2 .baikyaku a {
    margin-right: 0;
    width: 100%;
  }
}

.fttime {
  color: #fff;
  font-size: 0.8em;
  text-align: left;
}

/* box */
.insta {
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}

.insta .sns_list {
  display: flex !important;
  flex-direction: unset !important;
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .insta .sns_list {
    display: block !important;
  }
}

.insta .sns_text {
  display: none;
}

.insta .sns_photo {
  width: 100% !important;
}

.insta div.sns_list > div {
  border-bottom: none !important;
}

.txt_Top {
  writing-mode: vertical-rl;
}

.txtTop div {
  line-height: 1.7em;
  font-weight: 500;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .txtTop div {
    max-width: 500px;
    margin: 0 auto;
  }
}

.h2_01 h2 {
  text-align: left !important;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.7em;
  writing-mode: vertical-rl;
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

.h2_01 h2 span {
  font-size: 0.7em;
  display: block;
  font-weight: 400;
  text-align: end;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 26px;
    margin-bottom: 50px;
  }
}

.h2_01 h2:before {
  content: '';
  position: absolute;
  top: 0;
  display: inline-block;
  width: 1px;
  height: 250px;
  right: 0;
  background: #b20000;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2:before {
    writing-mode: inherit;
    height: 1px;
    width: 250px;
    bottom: 0;
    top: inherit;
  }
}

.h2_02 {
  position: absolute;
  top: -7%;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 834px) {
  .h2_02 {
    position: inherit;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .h2_02 {
    top: -3%;
  }
}

.h2_02 h2 {
  text-align: left !important;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.7em;
  writing-mode: vertical-rl;
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

.h2_02 h2 span {
  font-size: 0.5em;
  display: block;
  font-weight: 400;
  color: #b20000;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 26px;
  }
}

.h2_02 h2:before {
  content: '';
  position: absolute;
  top: 0;
  display: inline-block;
  width: 1px;
  height: 150px;
  left: 48%;
  background: #ccc;
}

.h2_03 h2 {
  text-align: left !important;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.7em;
  writing-mode: vertical-rl;
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

.h2_03 h2 span {
  font-size: 0.5em;
  display: block;
  font-weight: 400;
  color: #b20000;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 26px;
  }
}

.h2_03 h2:before {
  content: '';
  position: absolute;
  top: 0;
  display: inline-block;
  width: 1px;
  height: 150px;
  left: 48%;
  background: #ccc;
}

.btnwrap {
  position: relative;
}

.btnbox {
  justify-content: space-between;
}

.btnbox a:nth-child(1) {
  width: 48%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox a:nth-child(1) {
    width: 48%;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox a:nth-child(1) {
    width: 100% !important;
  }
}

.btnbox a:nth-child(1) .box {
  position: relative;
  overflow: inherit !important;
}

.btnbox a:nth-child(1) .box:before {
  content: url(../images/common/midashi_bg.png);
  position: absolute;
  bottom: -25%;
  left: 3%;
  z-index: 1;
}

.btnbox a:nth-child(2) {
  width: 48%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox a:nth-child(2) {
    width: 48%;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox a:nth-child(2) {
    width: 100% !important;
  }
}

.btnbox a:nth-child(2) .box {
  position: relative;
  overflow: inherit !important;
}

@media only screen and (max-width: 640px) {
  .btnbox a:nth-child(2) .box {
    margin-top: 50px;
  }
}

.btnbox a:nth-child(2) .box:before {
  content: url(../images/common/midashi_bg2.png);
  position: absolute;
  bottom: -25%;
  left: 3%;
  z-index: 1;
}

.btnbox a:nth-child(3) {
  width: 48%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox a:nth-child(3) {
    width: 48%;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox a:nth-child(3) {
    width: 100% !important;
  }
}

.btnbox a:nth-child(3) .box {
  position: relative;
  overflow: inherit !important;
  margin-top: 80px;
}

@media only screen and (max-width: 640px) {
  .btnbox a:nth-child(3) .box {
    margin-top: 50px;
  }
}

.btnbox a:nth-child(3) .box:before {
  content: url(../images/common/midashi_bg3.png);
  position: absolute;
  bottom: -25%;
  left: 3%;
  z-index: 1;
}

.btnbox a:nth-child(4) {
  width: 48%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox a:nth-child(4) {
    width: 48%;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox a:nth-child(4) {
    width: 100% !important;
  }
}

.btnbox a:nth-child(4) .box {
  position: relative;
  overflow: inherit !important;
  margin-top: 80px;
}

@media only screen and (max-width: 640px) {
  .btnbox a:nth-child(4) .box {
    margin-top: 50px;
  }
}

.btnbox a:nth-child(4) .box:before {
  content: url(../images/common/midashi_bg4.png);
  position: absolute;
  bottom: -25%;
  left: 3%;
  z-index: 1;
}

.btnbox .box {
  margin: 10px;
}

.btnbox2 {
  justify-content: space-between;
}

.btnbox2 a:nth-of-type(1) {
  width: 48%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox2 a:nth-of-type(1) {
    width: 48%;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(1) {
    width: 100% !important;
  }
}

.btnbox2 a:nth-of-type(1) .box {
  position: relative;
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(1) .box {
    margin: 10px;
  }
}

.btnbox2 a:nth-of-type(1) .box span {
  writing-mode: vertical-rl;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 52px;
  min-width: 30px;
  height: 140px;
  color: #fff;
  font-size: 1.3em;
  text-align: center;
  border: 1px solid #fff;
  padding: 10px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox2 a:nth-of-type(1) .box span {
    font-size: 1em;
    height: 100px;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(1) .box span {
    font-size: 1em;
  }
}

.btnbox2 a:nth-of-type(1) .box::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: '';
  width: 1px;
  height: 70px;
  background: #b20000;
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform .5s;
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(1) .box::after {
    height: 40px;
  }
}

.btnbox2 a:nth-of-type(1) .box:hover::after {
  transform-origin: left top;
  transform: scale(1, 1);
}

.btnbox2 a:nth-of-type(1) .box h3 {
  display: none;
}

.btnbox2 a:nth-of-type(2) {
  width: 48%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox2 a:nth-of-type(2) {
    width: 48%;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(2) {
    width: 100% !important;
  }
}

.btnbox2 a:nth-of-type(2) .box {
  position: relative;
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(2) .box {
    margin: 10px;
  }
}

.btnbox2 a:nth-of-type(2) .box::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: '';
  width: 1px;
  height: 45px;
  background: #b20000;
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform .5s;
}

.btnbox2 a:nth-of-type(2) .box:hover::after {
  transform-origin: left top;
  transform: scale(1, 1);
}

.btnbox2 a:nth-of-type(2) .box span {
  writing-mode: vertical-rl;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  min-width: 30px;
  height: 160px;
  color: #fff;
  font-size: 1.3em;
  text-align: center;
  border: 1px solid #fff;
  padding: 10px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox2 a:nth-of-type(2) .box span {
    font-size: 1em;
    height: 130px;
  }
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(2) .box span {
    font-size: 1em;
    height: 130px;
  }
}

.btnbox2 a:nth-of-type(2) .box::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: '';
  width: 1px;
  height: 45px;
  background: #b20000;
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform .5s;
}

@media only screen and (max-width: 640px) {
  .btnbox2 a:nth-of-type(2) .box::after {
    height: 40px;
  }
}

.btnbox2 a:nth-of-type(2) .box:hover::after {
  transform-origin: left top;
  transform: scale(1, 1);
}

.btnbox2 a:nth-of-type(2) h3 {
  display: none;
}

.newswrap2 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .newswrap2 {
    display: block;
  }
}

.newswrap2 .h2_03 {
  width: 20%;
}

@media only screen and (max-width: 834px) {
  .newswrap2 .h2_03 {
    width: 100% !important;
  }
}

.newswrap2 .newswrap {
  width: 68%;
}

@media only screen and (max-width: 834px) {
  .newswrap2 .newswrap {
    width: 100% !important;
  }
}

.hide {
  transform: translateY(-100%);
}

@media only screen and (max-width: 834px) {
  .hide {
    transform: none;
  }
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.sec_01 .txt_Top {
  width: 60%;
}

.sec_01 .txt_Top div {
  line-height: 4em;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_01 .txt_Top div {
    line-height: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .sec_01 .txt_Top div {
    line-height: 2em;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .sec_01 .txt_Top div {
    line-height: 3em;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .sec_01 .txt_Top div {
    line-height: 2.6em;
  }
}

.sec_01 .h2_01 {
  width: 20%;
}

@media only screen and (max-width: 834px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .txt_Top {
    width: 80% !important;
    margin: 0 auto;
  }
  .sec_01 .h2_01 {
    width: 100% !important;
  }
  .sec_01 .h2_01 h2 {
    writing-mode: inherit;
  }
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.qawrap {
  background: #d0e9ff;
  padding: 10px 30px 30px 30px;
}

.qawrap dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  color: #003e98;
  font-size: 24px;
  padding: 20px 0 !important;
}

@media only screen and (max-width: 640px) {
  .qawrap dt {
    font-size: 18px;
  }
}

.qawrap dd {
  width: 100% !important;
  border: none !important;
  color: #003e98;
  font-weight: 400;
  font-size: 24px;
  background: #fff !important;
  border-radius: 10px;
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .qawrap dd {
    font-size: 18px;
  }
}

.qawrap dl {
  display: block !important;
  width: 100% !important;
}

.uketsuke_table {
  max-width: 880px;
  font-size: 1.5em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

@media only screen and (max-width: 834px) {
  .uketsuke_table {
    font-size: 1.3em;
  }
}

.uketsuke_table td {
  border: none !important;
  padding: 30px !important;
}

.uketsuke_table td br {
  display: none;
}

@media only screen and (max-width: 640px) {
  .uketsuke_table td br {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .uketsuke_table td {
    padding: 15px !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .uketsuke_table td {
    padding: 30px 15px !important;
  }
}

.uketsuke_table .td_top {
  background: #f6ab00;
  color: #fff;
}

.uketsuke_table .td_topL {
  border-radius: 20px 0 0 0;
}

.uketsuke_table .td_topR {
  border-radius: 0 20px 0 0;
}

.uketsuke_table .td_btmL {
  border-radius: 20px 0 0 0;
}

.uketsuke_table .td_btmR {
  border-radius: 20px 0 0 0;
}

@media only screen and (max-width: 834px) {
  .nw_h2 {
    margin-left: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .nw_h2 {
    margin-left: 10px !important;
  }
}

.newswrap2 div:nth-child(3) {
  display: none;
}

.newswrap {
  position: relative;
}

.newswrap .news {
  height: 250px;
  overflow-y: scroll;
}

.newswrap .news dt {
  border: none !important;
  font-weight: 400;
  color: #333;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.newswrap .news dd {
  border: none !important;
  font-weight: 400;
  color: #333;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dd {
    display: block !important;
    width: 100% !important;
  }
}

.newswrap .news dl {
  padding: 15px 0 !important;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dl {
    display: block !important;
    width: 100% !important;
    padding: 20px 0 !important;
  }
}

.btn {
  width: 250px;
}

.btn a {
  color: #fff;
  background: #b20000;
  padding: 15px;
  display: block;
  font-size: 16px;
}

.btn a i {
  margin-left: 15px;
}

.txt > div {
  line-height: 1.7em;
  font-weight: 500;
}

.txt01 {
  font-size: 20px;
}

@media only screen and (max-width: 640px) {
  .txt01 {
    font-size: 18px;
  }
}

.txt02 {
  font-size: 40px;
  border-bottom: 10px solid #ffff00;
  padding-bottom: 20px;
  max-width: 760px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .txt02 {
    font-size: 30px;
  }
  .txt02 span {
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 640px) {
  .txt02 {
    font-size: 24px;
  }
  .txt02 span {
    font-size: 30px !important;
  }
}

.txt02 span {
  font-size: 70px;
}

.txt03 {
  color: #fff;
  font-size: 20px;
}

.txt04 {
  color: #fff;
  font-size: 18px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .txt04 > div {
    text-align: center !important;
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  text-align: center;
  width: 31%;
  margin: 5px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    margin-top: 20px;
  }
}

.bnrbox .box img {
  transition: all 1.8s ease-out;
}

.bnrbox .box img:hover {
  transform: scale(1.4);
}

.bnrbox .box img a:hover {
  opacity: 0.6;
  transition: 0.7s;
}

.bnrbox h3 {
  display: none;
}

.bnrbox .box article > div .kakomi {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 175px;
  min-width: 60px;
  height: 50px;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
}

.bnrbox .box article > div .kakomi span {
  display: block;
  font-size: 0.5em;
}

.bnrbox .box article > div .kakomi a {
  color: #fff;
  display: block;
}

.bnrbox2 {
  justify-content: center;
}

.bnrbox2 .box {
  text-align: center;
  margin: 5px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 640px) {
  .bnrbox2 .box {
    width: 100%;
    margin-top: 20px;
  }
}

.bnrbox2 .box img {
  transition: all 1.8s ease-out;
}

.bnrbox2 .box img:hover {
  transform: scale(1.4);
}

.bnrbox2 .box img a:hover {
  opacity: 0.6;
  transition: 0.7s;
}

.bnrbox2 h3 {
  display: none;
}

.bnrbox2 .box article > div .kakomi {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 175px;
  min-width: 60px;
  height: 50px;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
}

.bnrbox2 .box article > div .kakomi span {
  display: block;
  font-size: 0.5em;
}

.bnrbox2 .box article > div .kakomi a {
  color: #fff;
  display: block;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

.h2_04 {
  position: relative;
  margin-bottom: 10px;
}

.h2_04 h2 {
  font-size: 1.8em;
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  line-height: 1em;
}

.h2_04 h2:before {
  content: '';
  position: absolute;
  bottom: -15px;
  display: inline-block;
  width: 190px;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #b20000;
}

.h2_04 span {
  font-size: 0.5em;
  display: block;
  padding-top: 15px;
  font-weight: 500;
  color: #b20000;
}

.h3_01 h3 {
  font-size: 1.25em;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  background: #009445;
}

@media only screen and (max-width: 834px) {
  .h3_01 h3 {
    font-size: 20px;
  }
}

.h3_02 h3 {
  font-size: 1.5em;
  padding-bottom: 10px;
  border-bottom: 1px dotted #c1ab05;
  padding: 10px;
  color: #937f01;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h3_02 h3 {
    font-size: 20px;
  }
}

.menu_table {
  max-width: 800px;
  margin: 0 auto;
}

.menu_table dt {
  border: none !important;
  width: 100% !important;
}

.menu_table dd {
  border: none !important;
  text-align: right;
  width: 100% !important;
}

.menu_table dl {
  border-bottom: 1px solid #009445;
  display: block !important;
}

@media only screen and (max-width: 640px) {
  .menu_table dl {
    padding: 10px !important;
  }
}

.midashi p {
  position: relative;
  display: inline-block;
  padding: 0 55px;
  font-size: 1.5em;
  font-weight: bold;
}

.midashi p:before, .midashi p:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 45px;
  height: 2px;
  border-top: solid 1px #009445;
  border-bottom: solid 1px #009445;
}

.midashi p:before {
  left: 0;
}

.midashi p:after {
  right: 0;
}

.ancbtn table {
  border-collapse: separate;
  border-spacing: 15px;
}

@media only screen and (max-width: 640px) {
  .ancbtn table {
    border-collapse: collapse;
    border-spacing: 0;
  }
}

.ancbtn td {
  border: none !important;
  background: #b20000;
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .ancbtn td {
    width: 100% !important;
    border: solid 5px #fff !important;
  }
}

.ancbtn td a {
  display: block;
  height: 100%;
}

.table_siyou .td_Top {
  background: #009445;
  color: #fff;
}

.table_siyou .td_Left {
  background: #dcdcdc;
  width: 20% !important;
}

.table_gaiyou dt {
  border: none !important;
}

.table_gaiyou dd {
  border: none !important;
}

.table_gaiyou dl {
  border-bottom: 1px dotted #009445;
}

.table_access {
  padding: 30px;
  background: #dcdcdc;
}

.table_access td {
  width: 48%;
  border: none !important;
}

.table_access .tel2 a {
  color: #333;
}

.table_access span {
  font-size: 1.5em;
  background: #fff;
  padding: 5px;
}

@media only screen and (max-width: 640px) {
  .table_access span {
    font-size: 1.1em;
  }
}

.imgbox2 {
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .imgbox2 .slide-top {
    transform: translate(-50px, 0);
  }
}

.imgbox2 .box {
  width: 23%;
}

@media only screen and (max-width: 640px) {
  .imgbox2 .box {
    width: 100% !important;
    margin: 10px;
  }
}

.translate {
  display: none !important;
}

.sec_02 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .table_sakuhin {
    width: 100% !important;
  }
}

.sec_02 .table_sakuhin {
  width: 23%;
  margin: 10px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .table_sakuhin {
    width: 46% !important;
  }
}

.sec_02 .table_sakuhin td {
  border: none !important;
}

.sec_02 .table_sakuhin .title {
  background: #009445;
  color: #fff;
}

.sec_02 .table_sakuhin .img {
  padding: 0 !important;
}

.sec_02 .table_sakuhin .img img {
  width: 300px !important;
  height: 300px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 640px) {
  .sec_02 .table_sakuhin .img img {
    width: 100% !important;
    height: auto !important;
  }
}

.sec_02 .table_sakuhin .sakusha div {
  font-weight: bold;
}

.sec_02 .table_sakuhin .shurui div {
  font-size: 0.8em;
}

.sec_03 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sec_03 .img {
    width: 100% !important;
  }
  .sec_03 .txt {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_03 .img {
  width: 28%;
  margin-right: 15px;
}

@media only screen and (max-width: 834px) {
  .sec_03 .img {
    margin-right: 0;
  }
}

.sec_03 .txt {
  width: 58%;
}

.mapwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .mapwrap {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .mapwrap {
    display: block;
  }
}

.mapwrap .img {
  width: 28%;
}

@media only screen and (max-width: 834px) {
  .mapwrap .img {
    width: 100% !important;
  }
  .mapwrap .img img {
    height: auto !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .mapwrap .img {
    width: 100% !important;
    text-align: center;
  }
}

.mapwrap .img img {
  height: 246px;
}

.mapwrap .gmap {
  width: 68%;
}

@media only screen and (max-width: 834px) {
  .mapwrap .gmap {
    width: 100% !important;
    margin-top: 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .mapwrap .gmap {
    width: 100% !important;
    margin-top: 10px;
  }
}

.mapwrap .gmap iframe {
  height: 246px;
  width: 100%;
}

.sec_04 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 834px) {
  .sec_04 {
    display: block;
    padding: 30px;
  }
  .sec_04 .img {
    width: 100% !important;
    text-align: center !important;
  }
  .sec_04 .txt {
    width: 100% !important;
    padding-top: 20px;
  }
}

.sec_04 .img {
  width: 50%;
}

.sec_04 .txt {
  width: 50%;
  font-size: 1.25em;
  padding: 10px;
}

.table_wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .table_wrap {
    display: block;
  }
}

.table_wrap .table_cont {
  width: 30%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.table_wrap .table_cont img {
  width: 30% !important;
}

@media only screen and (max-width: 640px) {
  .table_wrap .table_cont {
    width: 100%;
  }
}

.table_wrap .table_cont td {
  border: none !important;
}

.main_txt .kenkou {
  font-size: 4.5em;
  font-family: "Noto Serif JP";
  color: #ac6b25;
  -webkit-text-stroke: 1px #FFF;
  text-stroke: 1px #FFF;
  font-weight: bold;
}

.cont a {
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.line a {
  justify-content: center;
  align-items: center;
  display: flex !important;
  white-space: nowrap;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .table_satei {
    width: 100% !important;
  }
}

.sec_05 .table_satei {
  width: 31%;
}

@media only screen and (max-width: 640px) {
  .sec_05 .table_satei {
    width: 100% !important;
  }
}

.sec_05 .table_satei td {
  border: none !important;
}

.imgbox {
  justify-content: flex-start;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox {
    justify-content: center;
  }
}

.imgbox .box {
  width: 20%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox .box {
    width: 36%;
    margin: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100% !important;
    margin-top: 10px;
  }
}

.imgbox h3 {
  text-align: center;
  white-space: nowrap;
}

.imgbox article > div {
  display: none;
}

.table_01 table {
  border-collapse: separate !important;
  border-spacing: 15px;
}

.table_01 td {
  border: none !important;
  background: #dcdcdc;
  padding: 20px;
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .table_01 td {
    width: 100%;
    display: block !important;
    margin-top: 15px;
  }
}

.table_02 table {
  border-collapse: separate !important;
  border-spacing: 15px;
}

.table_02 td {
  border: none !important;
  width: 22%;
}

@media only screen and (max-width: 640px) {
  .table_02 td {
    width: 100%;
    display: block !important;
    margin-top: 15px;
  }
}

.table_02 .td_bg {
  background: #dcdcdc;
}

.table_03 dt {
  background: #E8EDF1;
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-top: 60px solid #009445;
}

.txt_jusinki {
  background: #b20000;
  font-size: 2em;
  color: #fff;
  padding: 40px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #f6ab00;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.qa dd {
  width: 100% !important;
  border: none !important;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #ff8c00;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.qa dl {
  border-bottom: 1px dotted #dcdcdc;
  display: block !important;
  padding: 15px 0 !important;
}

.txt05 {
  max-width: 450px;
  font-size: 18px;
}

.txt05 span {
  position: relative;
  padding-left: 1.2em;
  /*アイコン分のスペース*/
  line-height: 1.4;
}

.txt05 span:before {
  font-family: "Font Awesome 5 pro";
  content: "\f14a";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  left: 0;
  /*アイコンの位置*/
  top: 0;
  /*アイコンの位置*/
  color: #0059b3;
  /*アイコン色*/
}

.txt06 {
  color: #e01010;
  font-size: 36px;
  line-height: 1.5em;
  text-shadow: 2px 2px 2px #ccc;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .txt06 {
    font-size: 30px;
  }
}

.txt06 br:nth-child(1) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .txt06 br:nth-child(1) {
    display: block;
  }
}

.txt06 br:nth-child(3) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .txt06 br:nth-child(3) {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .txt06 {
    font-size: 26px;
  }
}

.cont_tel div {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #009445;
}

.cont_tel article > div {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 75px;
}

@media only screen and (max-width: 640px) {
  .cont_tel article > div {
    color: #000;
    font-size: 30px;
  }
  .cont_tel article > div img {
    width: 13%;
  }
}

.title dt {
  border: none !important;
  font-size: 20px;
  border-bottom: 2px solid #001a81 !important;
}

@media only screen and (max-width: 640px) {
  .title dt {
    display: block !important;
    width: 100% !important;
  }
}

.title dd {
  border: none !important;
  font-size: 20px;
  border-bottom: 2px solid #ccc !important;
}

@media only screen and (max-width: 640px) {
  .title dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .title dl {
    display: block !important;
  }
}

.gmap iframe {
  width: 100%;
}

/* title */
.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 130px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 60px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  z-index: 0;
}

.bg_100per_wrap2 {
  position: relative;
  padding: 130px 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/news_bg.jpg) center;
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #009445;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #009445;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}
